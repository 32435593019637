import { defaultDoughnutProps } from '@enums/chart'
import { isNill } from '@utils/global-helpers'
import { ChartOptions } from 'chart.js'
import { format } from 'date-fns'

export const TIMEFRAME = {
  LAST_WEEK: 'week',
  LAST_MONTH: 'month',
  LAST_YEAR: 'year',
  ALL_TIME: 'all',
} as const

export type TTimeFrame = (typeof TIMEFRAME)[keyof typeof TIMEFRAME]

export const TIMEFRAME_TO_STRING = {
  [TIMEFRAME.LAST_WEEK]: 'Last week',
  [TIMEFRAME.LAST_MONTH]: 'Last month',
  [TIMEFRAME.LAST_YEAR]: 'Last year',
  [TIMEFRAME.ALL_TIME]: 'All time',
} as const

export const TimeFrameToNumbers = (orgCreationTime: number) => {
  return {
    [TIMEFRAME.LAST_WEEK]: 7,
    [TIMEFRAME.LAST_MONTH]: 30,
    [TIMEFRAME.LAST_YEAR]: 365,
    [TIMEFRAME.ALL_TIME]: orgCreationTime,
  }
}

export const defaultTimeFrame = TIMEFRAME.LAST_MONTH
export const defaultTimeFrameNum = 30

export const dashboardDoughnutProps = (
  disableLinks: boolean,
  moreThanOneItem: boolean,
): ChartOptions<'doughnut'> => {
  return {
    ...defaultDoughnutProps(moreThanOneItem),
    onHover: (e, item) => {
      if (disableLinks) return

      const canvas = document.querySelectorAll('.dashboard canvas') as NodeListOf<HTMLCanvasElement>

      if (isNill(canvas) || !canvas?.length) return

      const changeCursor = (cursor: string) => {
        canvas.forEach((canvas) => {
          canvas.style.cursor = cursor
        })
      }

      if (item?.length > 0) return changeCursor('pointer')
      else return changeCursor('default')
    },
  }
}

export const dbFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  roundingMode: 'floor',
})

export const getFormattedUrlDate = (date: string) => {
  // 2022-11-08 - Format
  const formatter = 'yyyy-MM-dd'

  return format(date, formatter)
}
