import { returnData } from '@services/http-helpers'
import { axiosInstance } from '@services/axios'
import { BASE_ROUTES, MFA_ROUTES } from '../services-consts'
import {
  EnableAuthenticatedMFAPayload,
  EnableMFAResp,
  EnableUnauthenticatedMFAPayload,
  GetEnableMFADataResp,
  VerifyMFAPayload,
} from '@typings/auth'
import { MessageResponse } from '@typings/generics'

class MFAServiceClass {
  getUnauthenticatedEnableMfaData = async (token: string) => {
    return axiosInstance
      .get<GetEnableMFADataResp>(MFA_ROUTES.ENABLE_PUBLIC_MFA, { params: { mfa_token: token } })
      .then(returnData)
  }

  enableUnauthenticatedMFA = async ({ otp_value, token }: EnableUnauthenticatedMFAPayload) => {
    return axiosInstance
      .post<EnableMFAResp>(MFA_ROUTES.ENABLE_PUBLIC_MFA, { mfa_token: token, otp_value })
      .then(returnData)
  }

  getAuthenticatedEnableMfaData = async () => {
    return axiosInstance.get<GetEnableMFADataResp>(MFA_ROUTES.ENABLE_MFA).then(returnData)
  }

  enableAuthenticatedMFA = async ({ otp_value }: EnableAuthenticatedMFAPayload) => {
    return axiosInstance.post<EnableMFAResp>(MFA_ROUTES.ENABLE_MFA, { otp_value }).then(returnData)
  }

  enableMFA = async (payload: EnableUnauthenticatedMFAPayload) => {
    return payload.token ? this.enableUnauthenticatedMFA(payload) : this.enableAuthenticatedMFA(payload)
  }

  getEnableMfaData = async (token: string | null) => {
    return token ? this.getUnauthenticatedEnableMfaData(token) : this.getAuthenticatedEnableMfaData()
  }

  verifyMFA = async (payload: VerifyMFAPayload) => {
    return axiosInstance.post<MessageResponse>(MFA_ROUTES.VERIFY_MFA, payload).then(returnData)
  }

  removeAuthenticatedMfa = async () => {
    return axiosInstance.delete<MessageResponse>(BASE_ROUTES.MFA).then(returnData)
  }
}

export const MFAService = new MFAServiceClass()
