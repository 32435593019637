import { Query, QueryKey, QueryClient as QueryClientInstance, QueryCache } from '@tanstack/react-query'
import { useCommonStore } from '@stores/useCommonStore'
import { AxiosError } from 'axios'

interface CustomQueryMeta extends Record<string, unknown> {
  snackbarErrorMsg?: string
  errorCallback?: (error: AxiosError, query: Query<unknown, unknown, unknown, QueryKey>) => void
}

declare module '@tanstack/react-query' {
  interface Register {
    queryMeta: CustomQueryMeta
    defaultError: AxiosError
  }
}

const toggleSnackBar = useCommonStore.getState().toggleSnackBar

export const QueryClient = new QueryClientInstance({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: 'always',
      gcTime: 0, // Don't keep query data in memory
      retry: import.meta.env.MODE === 'development' ? 0 : 1,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // 1s, 2s, 4s, 8s, 16s, 30s
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.log(error)

      const { meta } = query
      if (!meta) return

      if (meta.errorCallback) meta.errorCallback(error, query)
      if (meta.snackbarErrorMsg) toggleSnackBar(meta.snackbarErrorMsg)
    },
  }),
})
