import { FC, useState } from 'react'
import LogoutIcon from '@static/common/logout.svg?react'
import { USER_ROLE } from '../../../../enums/users'
import { useOrgStore } from '@stores/useOrgStore'
import TooltipElement from '@components/Common/TooltipElement'
import { useHistory } from 'react-router-dom'
import KeyIcon from '@static/sidemenu/credentials-icon.svg?react'
import PopoverElement from '../../../Common/PopoverElement'
import { EnableMFAModal } from '@components/Common/EnableMFAModal/EnableMFAModal'
import { useCommonStore } from '@stores/useCommonStore'
import Modal from '@components/Common/Modal'
import DisableMFAIcon from '@static/issues/open-issue.svg?react'
import { useQueryClient } from '@tanstack/react-query'
import { useUserStore } from '@stores/useUserStore'
import { AppInitResp } from '@services/apis/organization'
import { BasePopoverItem } from '../../../Common/PopoverElement/PopoverElement'
import { getMfaModalNotes } from '@utils/getMfaModalNotes'
import { UserTypeIcon } from '@components/Common/UserTypeIcon/UserTypeIcon'
import { MFAService } from '@services/apis/mfa'

interface UserPopoverProps {
  fullName: string
  anchorEl: HTMLElement
  appInitData: AppInitResp
  onClose: () => void
}

const UserPopover: FC<UserPopoverProps> = ({ fullName, anchorEl, onClose, appInitData }) => {
  const [disableMfaModal, setDisableMfaModal] = useState<boolean>(false)

  const { replace } = useHistory()

  const organization = useOrgStore((state) => state.organization)
  const toggleSnackBar = useCommonStore((state) => state.toggleSnackBar)
  const { user, logoutUser, setShowEnableMFAModal, showEnableMFAModal, setUser } = useUserStore((state) => ({
    user: state.user,
    logoutUser: state.logoutUser,
    setShowEnableMFAModal: state.setShowEnableMFAModal,
    showEnableMFAModal: state.showEnableMFAModal,
    setUser: state.setUser,
  }))

  const queryClient = useQueryClient()

  const removeMfa = async () => {
    try {
      setDisableMfaModal(false)
      await MFAService.removeAuthenticatedMfa()
      setUser({ ...user, mfa_enabled: false })
    } catch (err) {
      console.log(err)
      toggleSnackBar('Failed to remove MFA, please try again later')
    }
  }

  const getPopoverList = (): BasePopoverItem[] => {
    const baseList = [
      {
        name: 'Logout',
        onClick: () => logoutUser(replace, queryClient),
        icon: <LogoutIcon />,
      },
    ] as BasePopoverItem[]

    if (!user.mfa_enabled) {
      baseList.unshift({
        name: 'Enable MFA',
        onClick: () => setShowEnableMFAModal(true),
        icon: <KeyIcon />,
        tooltipProps: {
          title: appInitData.is_sso_auth && 'MFA is disabled when logged in via SSO',
          placement: 'left',
        },
        disabled: appInitData.is_sso_auth,
      })
    } else {
      baseList.unshift({
        name: 'Disable MFA',
        onClick: () => setDisableMfaModal(true),
        icon: <DisableMFAIcon />,
      })
    }

    return baseList
  }

  return (
    <>
      <PopoverElement
        customPopoverClass='user-popover__paper'
        darkMode
        anchorPositions={{
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        }}
        beforeListChildren={
          <>
            <div className='user-popover__top'>
              <TooltipElement title={user.is_admin ? 'Admin' : 'Analyst'} placement='left'>
                <div className='user-popover__top--item'>
                  <UserTypeIcon userType={user.is_admin ? USER_ROLE.admin : USER_ROLE.analyst} />
                  <span className='user-name'>{fullName}</span>
                </div>
              </TooltipElement>

              <span className='user-email'>{user.email}</span>
            </div>

            <div className='divider' />
          </>
        }
        anchorEl={anchorEl}
        handleCloseMenu={onClose}
        list={getPopoverList()}
        isOptionActive={(opt) =>
          opt.name.toLowerCase().includes('mfa') && (showEnableMFAModal || disableMfaModal)
        }
      />

      <EnableMFAModal />

      <Modal
        open={disableMfaModal}
        title={{ text: 'Remove Multi-Factor Authentication', icon: 'warning' }}
        text={{
          desc: `Are you sure you want to remove MFA?`,
        }}
        buttonProps={{
          confirm: {
            onClick: removeMfa,
            text: 'Remove MFA',
            iconProps: { icon: 'warning' },
            variant: user.id === user.id && organization.settings.enforce_mfa ? 'danger' : 'secondary',
          },
          cancel: { onClick: () => setDisableMfaModal(false), text: 'Cancel' },
        }}
        notes={getMfaModalNotes({
          organization,
          user,
          isCurrentUser: true,
        })}
        width={500}
        onClose={() => setDisableMfaModal(false)}
        transitionDuration={200}
      />
    </>
  )
}

export default UserPopover
