import React, { CSSProperties, FC, Fragment } from 'react'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@static/common/times.svg?react'
import RoundCheckmark from '@static/common/round-checkmark.svg?react'
import ErrorIcon from '@static/common/snack-error.svg?react'
import { isNill } from '@utils/global-helpers'
import Slide, { SlideProps } from '@mui/material/Slide'
import { useCommonStore } from '@stores/useCommonStore'
import { SnackBarType } from '@typings/snackbar'
import WarningIcon from '@static/issues/open-issue.svg?react'

const TransitionRight = (props: Omit<SlideProps, 'direction'>) => <Slide {...props} direction='right' />

const getTypeProps = (type: SnackBarType) => {
  switch (type) {
    case 'success':
      return {
        icon: RoundCheckmark,
        color: 'var(--green-3)',
      }

    case 'info':
      return {
        icon: WarningIcon,
        color: 'var(--tertiary-3)',
      }

    default:
      return {
        icon: ErrorIcon,
        color: 'var(--red-1)',
      }
  }
}

const SnackBarElement: FC = () => {
  const { snackBarData, closeSnackBar } = useCommonStore((state) => ({
    snackBarData: state.snackBarData,
    closeSnackBar: state.closeSnackBar,
  }))

  const { message } = snackBarData || {}
  const { type } = snackBarData?.data || {}

  const handleClose = (e: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway' || reason === 'escapeKeyDown') return
    closeSnackBar()
  }

  const typeProps = getTypeProps(type)

  return (
    <div
      className='snackbar-element'
      onClick={(e) => e.stopPropagation()}
      style={{ '--bar-clr': typeProps.color } as CSSProperties}
    >
      <Snackbar
        classes={{ root: 'snackbar-root' }}
        TransitionComponent={TransitionRight}
        transitionDuration={150}
        autoHideDuration={3000}
        disableWindowBlurListener // If true, the autoHideDuration timer will expire even if the window is not focused.
        open={!isNill(snackBarData)}
        onClose={handleClose}
        message={
          message?.length > 0 && (
            <div className='message'>
              <typeProps.icon />
              <span className='message__text'>{message}</span>
            </div>
          )
        }
        action={
          <Fragment>
            <IconButton
              className='close-btn'
              size='small'
              color='inherit'
              onClick={(e) => handleClose(e, null)}
            >
              <CloseIcon />
            </IconButton>
          </Fragment>
        }
      />
    </div>
  )
}
export default SnackBarElement
